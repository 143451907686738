const key_labels = {
	zone_name: "Zone",
	state_name: "State",
	city_name: "City",
	location: "Location",
	media_type: "Media Type",
	illumination: "Illumination",
	width: "Width",
	height: "Height",
	quantity: "Quantity",
	area:"Size",
	vendor_name: "Vendor Name",
	Rank_net_saliency_citywise:"Rank Net Saliency Citywise",
	rank_net_saliency_locationwise: "Rank Net Saliency Locationwise",
	efficiency: "Efficiency",
	effective_impression:"Effective Impression",
	visibility_duration:"visibility Duration",
	duration: "Duration",
	imp_per_month: "Impression Per Month",
	rental_per_month: "Rental Price per Month",
	cost_for_duration: "Cost for Duration",
	printing_rate: "Printing Rate",
	mountig_rate: "Mounting Rate",
	printing_cost: "Printing Cost",
	mounting_cost: "Mounting Cost",
	impression: "Impression",
	total_cost: "Total Cost",
};

export default key_labels;
